import {extend, localize} from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'
import {
	alpha as rule_alpha,
	alpha_dash as rule_alpha_dash,
	alpha_num as rule_alpha_num,
	between as rule_between,
	confirmed as rule_confirmed,
	digits as rule_digits,
	email as rule_email,
	integer as rule_integer,
	length as rule_length,
	min as rule_min,
	regex as rule_regex,
	required as rule_required,
	max as rule_max
} from 'vee-validate/dist/rules'

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend('required', rule_required)

export const email = extend('email', rule_email)

export const min = extend('min', rule_min)

export const max = extend('max', rule_max)

export const confirmed = extend('confirmed', rule_confirmed)

export const regex = extend('regex', rule_regex)

export const between = extend('between', rule_between)

export const alpha = extend('alpha', rule_alpha)

export const integer = extend('integer', rule_integer)

export const digits = extend('digits', rule_digits)

export const alphaDash = extend('alpha-dash', rule_alpha_dash)

export const alphaNum = extend('alpha-num', rule_alpha_num)

export const length = extend('length', rule_length)

localize({
	ru: {
		messages: ru.messages,
		names: {
			email: 'Email',
			password: 'Password',
		},
		fields: {
			password: {
				min: '{_filed_} слишком короткий',
			},
		},
	},
})