<template>
  <b-modal active>
    <div class="is-fluid p-3">
      <div
        class="box"
        :style="{
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundImage: `url('/policyBack.svg')`,
          height: 'fit-content',
        }"
      >
        <div class="columns is-mobile is-multiline">
          <div class="column is-12">
            <h3 class="has-text-centered has-text-weight-bold w-full">Полис автострахования</h3>
          </div>
          <div class="column is-6">
            <text-field :bold="true" label="Серия полиса" :value="data.serial" />
          </div>
          <div class="column is-6">
            <text-field
              :bold="true"
              :right="true"
              label="Номер полиса"
              :value="data.number"
            />
          </div>
          <div class="column is-12">
            <text-field label="Дата окончания" :value="format(data.ended)" />
          </div>
          <div class="column is-12">
            <text-field label="ТС" :value="`${data.brand} ${data.model}`" />
          </div>
          <div class="column is-12">
            <b-button expanded type="is-primary" @click="$emit('onSave')">Сохранить</b-button>
          </div>
          <div class="column is-12">
            <b-button expanded type="is-light" @click="$emit('onCancel')">Скрыть</b-button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import TextField from '../Policy/TextField.vue';
import dayjs from 'dayjs'
export default {
    components: {
        TextField
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    methods: {
        format(date) {
            return dayjs(date).format('DD.MM.YY')
        },
    }
};
</script>

<style>
</style>