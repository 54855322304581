<template>
  <div class="is-flex is-flex-direction-column">
      <span class="mb-2 is-size-7 pl-3" :class="{ 'has-text-right': right, 'pr-3 pl-0': right }">
          {{ label }}
      </span>
      <div class="text-field is-size-6 p-2 pl-4 pr-4 has-background-white-bis" :class="{ 'has-text-weight-bold': bold }">
          {{ value }}
      </div>
  </div>
</template>

<script>
export default {
    props: ['value', 'label', 'right', 'bold']
}
</script>

<style lang="scss">
.text-field {
    border-radius: 8px;
}
</style>