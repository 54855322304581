<template>
    <ValidationObserver #default="{ invalid }">
        <preview v-if="!!data" :data="data" @onSave="onSave" @onCancel="onCancel" />
        <form @submit.prevent="onSubmit" class="box p-4 has-background-white">
            <h3 class="has-text-centered has-text-weight-bold w-full mb-4">Поиск и проверка документов</h3>
            <ValidationProvider name="гос.номер" :rules="{ required: true, regex: /[а-я]{1}\d{3}[а-я]{2}\d{2,3}/ui }" #default="{ errors }">
                <b-field custom-class="is-small" label="Введите госномер ТС" :message="errors[0]" :type="errors[0] && 'is-danger'">
                    <b-input v-model="licensePlate" placeholder="А111АА111" />
                </b-field>
            </ValidationProvider>
            <ValidationProvider name="email" :rules="{ required: true, email: true }" #default="{ errors }" v-if="!internalEmail">
                <b-field custom-class="is-small" label="Введите Email" :message="errors[0]" :type="errors[0] && 'is-danger'" class="mt-4">
                    <b-input v-model="mail" placeholder="example@domain.ru" />
                </b-field>
            </ValidationProvider>
            <b-button :loading="isLoading" expanded type="is-primary" class="mt-4 has-text-weight-bold" :disabled="invalid" @click="onSubmit">Поиск</b-button>
        </form>
    </ValidationObserver>
</template>

<script>
import { email, required, regex } from '@/utils/validations'
import md5 from 'md5'
import Preview from './Preview.vue';

export const gs = (param) => {
  return param ? '2OaJtQ' : () => {
    return 'ooNbd';
  };
}

export default {
  components: { Preview },

  props: {
      mode: {
          type: String
      },
      internalEmail: {
          type: String
      }
  },
    data() {
        return {
            email,
            required,
            regex,

            isLoading: false,

            licensePlate: '',
            mail: '',

            data: null
        }
    },
    methods: {
        async onSubmit() {
            this.isLoading = true
            try {
                const key = md5(`n78Qt${gs(1)}${gs(
                    undefined
                )()}/${new Date().toISOString().substr(0, 13)}`)

                const { data } = await this.$api.getPolicyInfo({ licensePlate: this.licensePlate, email: this.internalEmail || this.mail, key })

                if (!data.data.ended) {
                    const res = await this.$api.getPolicyDate({
                        licensePlate: this.licensePlate,
                        serial: data.data.serial,
                        number: data.data.number,
                    }).catch(() => {})

                    if (res && res.data && res.data.data && res.data.data.ended) {
                        data.data.ended = res.data.data.ended
                    }
                }

                this.data = data.data

                if (this.mode !== 'policy') {
                    this.$router.push({ ...this.$route, query: { ...data.data, email: this.mail } }).catch(() => {})
                } else {
                    this.$router.push({ ...this.$route, query: { ...this.$route.query, policies: [...(Array.isArray(this.$route.query.policies) ? this.$route.query.policies : [this.$route.query.policies]), this.licensePlate] } }).catch(() => {})
                }
            } catch (error) {
                this.$buefy.snackbar.open({
                    message: 'Действующий полис не найден или возникла ошибка',
                    type: 'is-warning',
                    position: 'is-top',
                    indefinite: true,
                })
                console.error(error)
            } finally {
                this.isLoading = false
            }
        },
        onSave() {
            if (this.mode !== 'policy') {
                 this.$router.push({ name: 'policy', query: { policies: [this.licensePlate], email: this.mail } })
            } else {
                this.$emit('save', this.data)
                this.data = null
            }
        },
        onCancel() {
            this.data = null
            this.licensePlate = ''
        }
    }
}
</script>

<style></style>
